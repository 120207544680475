import * as React from 'react'
import { Flex, Text, Button } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import BasePage from '../../components/basePage'
import { useForm } from 'react-hook-form'
import { getUserByEmail } from '../../services/auth'
import { useEffect, useState } from 'react'
import Input from '../../components/input'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { object, string } from 'yup'
import { useAuth } from '../../hooks/useAuth'
import { navigate } from 'gatsby'

const Login = ({ basePath = '/login', inVenue }) => {
	const [isLoading, setLoading] = useState(false)
	// const { isLoggedIn, missingRequired, fetchProfile } = useAuth()

	const resolver = yupResolver(
		object()
			.shape({
				email: string().trim().email().required()
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		clearErrors,
		setError,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onChange',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchEmail = watch('email')

	const onSubmit = async (data) => {
		if (!data?.email || !!errors?.email) return
		clearErrors()
		try {
			setLoading(true)
			const email = data.email.toLowerCase().trim()

			const result = await getUserByEmail(email)

			if (!result?.data?.userId) {
				setLoading(false)
				return setError('email', {
					type: 'custom',
					message:
						'Unable to verify your email, check your email is correct'
				})
			}

			navigate(`/guests/${result?.data?.userId}`)

			// await signInOrCreateUser(email)
			// await fetchProfile()
		} catch (e) {
			setLoading(false)
			return setError('email', {
				type: 'custom',
				message:
					'Unable to verify your email, check your email and internet connection'
			})
		}
	}

	// useEffect(() => {
	// 	if (isLoading) {
	// 		handleLogin().then(() => null)
	// 	}
	// }, [isLoading, missingRequired, isLoggedIn])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				access={0}
				parent="login"
				theme="dark"
				primaryBtn={
					<Button
						mt="8px"
						variant="primary"
						isLoading={isLoading}
						disabled={!watchEmail || isLoading}
						type="submit"
					>
						Continue
					</Button>
				}
			>
				<Flex alignItems="center" flexDirection="column" mt="0">
					<Text color="white" as={'h1'} variant="m">
						WELCOME TO
					</Text>

					<StaticImage
						width={213}
						alt="Logo"
						src="../../images/logo-white.png"
					/>

					<Flex mt="10" justifyContent="center" ml="100px" mb="-48px">
						<StaticImage
							width={190}
							alt="Playing"
							src="../../images/playing.png"
						/>
					</Flex>

					<Input
						name="email"
						id="email"
						type="email"
						variant="filled"
						placeholder="Your email"
						mb="10px"
						register={register}
						errors={errors}
					/>
					<Text textAlign="center" variant="m" color="white">
						Tip: Enter the same email you’ve used previously to see
						your leaderboards
					</Text>
				</Flex>
			</BasePage>
		</form>
	)
}

export default Login
